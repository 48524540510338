import React from 'react';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';
import Container from '../../components/Container';
import NewsContent from '../../components/NewsContent';

const EventNews = ({ data }) => {
  const pageData = data.strapi && !isEmpty(data.strapi.event) && camelizeKeys(data.strapi.event);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;

  const {
    numberOfNews, content, additionalNews, mainNews,
  } = pageData;
  const isExist = !isEmpty(content)
    && content.every((dynamic) => dynamic.typename !== 'STRAPI_ComponentSectionsNewsHero');

  const contentNews = !isEmpty(content) && content.find((item) => item.news);

  const { news: heroNews } = !isEmpty(contentNews) && contentNews;

  const allEventNews = pageData && [...additionalNews, ...mainNews];

  const uniqueNews = allEventNews && heroNews && allEventNews
    .filter((news) => !heroNews
      .some((oneNews) => oneNews.data?.id === news.data?.id));

  const spreadingObject = (news) => !isEmpty(news) && news.map((item) => item.data);

  const eventBannersBetweenNews = pageData
    && pageData.content
    && pageData.content.filter(
      (c) => c.typename
        === 'STRAPI_ComponentSectionsBannerWithOneEventBetweenTheNews',
    );
  return (
    <Layout headerData={headerData} footerData={footerData} signUpData={signUpData}>
      {isExist && !isEmpty(uniqueNews) && (
        <div className="all-news-container">
          <Container>
            <NewsContent
              data={{
                news: spreadingObject(uniqueNews),
                isEvent: true,
                numberOfNews,
                eventTitle: pageData.title,
              }}
              eventBannersBetweenNews={eventBannersBetweenNews}
            />
          </Container>
        </div>
      )}
      {pageData && !isEmpty(pageData.content) && (
        <DynamicZone
          components={pageData.content}
          eventBannersBetweenNews={eventBannersBetweenNews}
          data={{
            news: spreadingObject(uniqueNews), isEvent: true, numberOfNews, eventTitle: pageData.title,
          }}
        />
      )}
    </Layout>
  );
};

EventNews.propTypes = {
  data: shape({
    strapi: shape(),
  }),
};

EventNews.defaultProps = {
  data: {
    strapi: {},
  },
};

export default EventNews;

export const eventQuery = graphql`
  query getEventNewsPage($id: ID!) {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      event(id:$id) {
        title
        id
        slug
        numberOfNews
        mainNews {
          label
          data {
            type {
              label
            }
            id
            description
            date
            isFeatured
            title
            secondaryImage {
              url
            }
            commonImage {
              url
            }
            primaryImage {
              url
            }
            slug
          }
        }
        additionalNews {
          label
          data {
            id
            description
            title
            date
            slug
            isFeatured
            type {
              label
            }
            commonImage {
              url
            }
            secondaryImage {
              url
            }
            primaryImage {
              url
            }
          }
        }
        content {
          ... on STRAPI_ComponentSectionsBannerWithOneEventBetweenTheNews {
            id
            afterNewsIndex
            mobileBackground {
              url
            }
            __typename
            data {
              bannerLinks {
                id
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  id
                  label
                  path
                  openInNewWindow
                }
              }
              event {
                id
                label
                data {
                  date
                  dateClock
                  dateDayOfWeek
                  dateMonth
                  dateTimezone
                  id
                  slug
                  title
                  description
                  image {
                    url
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsNewsHero {
            id
            backgroundText
            __typename
            news {
              label
              data {
                id
                isFeatured
                description
                date
                slug
                title
                type {
                  label
                }
                commonImage {
                  url
                }
                primaryImage {
                  url
                }
                secondaryImage {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
